.bluetick2 {
  margin: 0;
}

.bluetick-verification4 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 90px;
  font-weight: 600;
}

.bluetick-logo-child15,
.bluetick-logo-child16 {
  position: absolute;
  top: 0;
  left: 0;
  width: 232px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child16 {
  left: 262px;
  width: 152px;
}

.bluetick-logo-child17,
.bluetick-logo-child18,
.bluetick-logo-child19 {
  position: absolute;
  top: 0;
  left: 444px;
  width: 324px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child18,
.bluetick-logo-child19 {
  left: 798px;
  width: 188px;
}

.bluetick-logo-child19 {
  left: 1022px;
  width: 198px;
}

.bluetick-logo-child20,
.bluetick-logo-child21,
.bluetick-logo-child22 {
  position: absolute;
  top: 0;
  left: 1250px;
  width: 272px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child21,
.bluetick-logo-child22 {
  top: 86px;
  left: 0;
  width: 265px;
}

.bluetick-logo-child22 {
  left: 295px;
  width: 232px;
}

.bluetick-logo-child23,
.bluetick-logo-child24,
.bluetick-logo-child25 {
  position: absolute;
  top: 86px;
  left: 557px;
  width: 176px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child24,
.bluetick-logo-child25 {
  left: 763px;
  width: 232px;
}

.bluetick-logo-child25 {
  left: 1025px;
  width: 164px;
}

.bluetick-logo-child26,
.bluetick-logo-child27,
.bluetick-logo-child28 {
  position: absolute;
  top: 86px;
  left: 1219px;
  width: 258px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child27,
.bluetick-logo-child28 {
  top: 172px;
  left: 0;
  width: 324px;
}

.bluetick-logo-child28 {
  left: 354px;
  width: 232px;
}

.bluetick-logo-child29,
.bluetick-logo-child30,
.bluetick-logo-child31 {
  position: absolute;
  top: 172px;
  left: 616px;
  width: 258px;
  height: 56px;
  object-fit: cover;
}

.bluetick-logo-child30,
.bluetick-logo-child31 {
  left: 904px;
  width: 282px;
}

.bluetick-logo-child31 {
  left: 1216px;
  width: 246px;
}

.bluetick-logo1,
.bluetick-verification3 {
  position: absolute;
  top: 285px;
  left: 0;
  width: 1522px;
  height: 228px;
}

.bluetick-verification3 {
  top: 6545px;
  left: 12%;
  height: 513px;
  text-align: left;
  font-size: 100px;
  color: #fff;
}